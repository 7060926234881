import { Box, Dialog } from "@mui/material";
import { Text, Button } from "@lipihipi/ui-components";
import { SufficientFunds } from "../../Icons";
import { useState } from "react";
import TopupModal from "./Topup";

type Props = {
  open: boolean;
  onClose: any;
};
const InsufficientFundModal = ({ open, onClose }: Props) => {
  const [openTopup, setOpenTopup] = useState(false);
  return (
    <>
      <Box>
        <Dialog open={open} onClose={onClose} keepMounted>
          <Box p={4} textAlign="center">
            <SufficientFunds />
            <Box py={3}>
              <Text variant="h3">Insufficient Funds</Text>
              <Text variant="body1" pt={1}>
                In order to proceed further, please add
                <br /> some funds to your wallet.
              </Text>
            </Box>
            <Button
              variant="contained"
              size="large"
              sx={{ padding: "15px 60px", borderRadius: "8px" }}
              onClick={() => {
                setOpenTopup(true);
                onClose(false);
              }}
            >
              Add now
            </Button>
          </Box>
        </Dialog>
      </Box>
      <TopupModal open={openTopup} onClose={setOpenTopup} />
    </>
  );
};

export default InsufficientFundModal;
