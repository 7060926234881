import { PrimaryLayout } from "../../Components";
import { Box, Grid } from "@mui/material";
import {
  Button,
  Card,
  CustomTable,
  Form,
  InputBox,
  Text,
} from "@lipihipi/ui-components";
import handleToast, { error } from "../../util/Toast";
import { requestTransfer } from "../../Services/Service";
import { useContext, useState } from "react";
import { AppContext } from "../../App";
import { useNavigate, useParams } from "react-router-dom";
import { WALLET } from "../../RouteConstant";
import PayoutTransferModal from "../../Components/Modal/PayoutTransafer";

const RedeemAmount = () => {
  const { state } = useContext<any>(AppContext);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const handleSubmit = (values: any) => {
    values.id = id;
    return new Promise((res: any) => {
      if (state.user.wallet.balance < parseInt(values.amount)) {
        handleToast("Please enter amount as per your wallet", error);
        res("");
        return;
      }
      requestTransfer(
        values,
        (response: any) => {
          console.log(response);
          // handleToast('Payout transfer Successfully');
          setOpen(true);
        },
        (error: any) => {
          handleToast(error.data.message);
          res("");
        }
      );
    });
  };
  return (
    <>
      <PrimaryLayout>
        <Grid justifyContent="center" container spacing={2}>
          <Grid item xs={12} sm={6} md={7}>
            <Card
              px={2}
              sx={{
                boxShadow: "none",
                border: "1px solid rgba(57, 57, 63, 0.10)",
              }}
              disableHeader
            >
              <Box>
                <Text variant="h1">Enter Redeem Amount</Text>
                <Text variant="body1" mt={0.4}>
                  To redeem funds from your wallet, please add your UPI ID, and
                  then you can proceed.
                </Text>
                <Text variant="h3" mt={4}>
                  Available points in your wallet is {state.user.wallet.balance}
                </Text>
              </Box>
              <Form
                initialValues={{ amount: "", id: "" }}
                validateOnMount={true}
                onSubmit={handleSubmit}
                render={({
                  handleChange,
                  isValid,
                  isSubmitting,
                  submitForm,
                }: any) => {
                  return (
                    <>
                      <Box mt={4}>
                        <InputBox
                          name="amount"
                          fullWidth
                          label="Enter points you wish to redeem"
                          placeholder="Entere here..."
                          variant="outlined"
                          autoFocus
                          required
                          onChange={handleChange}
                        />
                      </Box>
                      <Box mt={1} display="flex" justifyContent="space-between">
                        <Text variant="caption" color="#524B6B">
                          {" "}
                          one point is 1 rupees
                        </Text>
                      </Box>

                      <Box display="flex" mt={8} columnGap={2}>
                        <Button
                          variant="outlined"
                          size="large"
                          fullWidth
                          sx={{ padding: "15px 40px", borderRadius: "8px" }}
                          onClick={() => navigate(WALLET)}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          size="large"
                          fullWidth
                          sx={{ padding: "15px 20px", borderRadius: "8px" }}
                          disabled={!isValid || isSubmitting}
                          onClick={isValid ? submitForm : () => {}}
                        >
                          Submit
                        </Button>
                      </Box>
                    </>
                  );
                }}
              />
            </Card>
          </Grid>
        </Grid>
        <PayoutTransferModal open={open} onClose={setOpen} />
      </PrimaryLayout>
    </>
  );
};

export default RedeemAmount;
