import React from "react";
import { Box } from "@mui/material";
import { PrimaryLayout } from "../../../Components";
import { Card, Text, Form, Button } from "@lipihipi/ui-components";
import { Grid, TextField } from "@mui/material";
import { IDVerify } from "../../../Icons";
import { LeftContent } from ".";
import { verifyKycOTP } from "../../../Services/Service";
import { useNavigate, useParams } from "react-router-dom";
import { KYCDETAIL } from "../../../RouteConstant";
import handleToast from "../../../util/Toast";

const VerificationOTP = () => {
  const navigate = useNavigate();
  const { refId } = useParams();

  const digitValidate = (value: string) => {
    return value.replace(/[^0-9]/g, "");
  };

  const handleOtpChange = (
    index: number,
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    const target = event.target as HTMLInputElement;
    const value = digitValidate(target.value);
    if (value) {
      const nextInput =
        document.querySelectorAll<HTMLInputElement>(".otp")[index + 1];
      if (nextInput) nextInput.focus();
    } else if (event.key === "Backspace" && index > 0) {
      const prevInput =
        document.querySelectorAll<HTMLInputElement>(".otp")[index - 1];
      if (prevInput) prevInput.focus();
    }
  };

  return (
    <PrimaryLayout>
      <Box>
        <Box sx={{ paddingTop: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={5}>
              <Card
                px={2}
                py={2}
                disableHeader
                sx={{
                  boxShadow: "none",
                }}
              >
                <Box>
                  <Text
                    variant="h3"
                    sx={{
                      pb: 1,
                      mb: 1,
                      fontWeight: "600",
                      fontSize: "20px",
                      borderBottom: "1px solid #D6DDEB",
                    }}
                  >
                    ID verification
                  </Text>

                  <Text variant="subtitle1">
                    Benefits of ID verification at LIPIJobs
                  </Text>
                  <Box
                    display="flex"
                    justifyContent="center"
                    columnGap={3}
                    py={2}
                    alignItems="center"
                  >
                    <IDVerify />
                    <Text variant="subtitle1" textAlign="center">
                      Your Identity has
                      <br /> been{" "}
                      <Text
                        sx={{ fontWeight: 600, color: "#00C385" }}
                        component="strong"
                      >
                        verified!
                      </Text>
                    </Text>
                  </Box>
                  <Box display="flex" flexDirection="column" rowGap={2}>
                    {LeftContent.map((item, index) => (
                      <Text
                        display="flex"
                        columnGap={1}
                        sx={{
                          background: "#FCFCFC",
                          borderRadius: "8px",
                          padding: "10px 20px",
                          border: "1px solid rgba(22, 20, 88, 0.20)",
                        }}
                        variant="subtitle1"
                      >
                        {" "}
                        {item.icon} {item.title}
                      </Text>
                    ))}
                  </Box>
                </Box>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={7}>
              <Card px={3} py={3} disableHeader sx={{}}>
                <Box>
                  <Text variant="h1" com sx={{ mt: 1, color: "#25324B" }}>
                    Enter your Verification Code
                  </Text>

                  <Text
                    variant="subtitle2"
                    sx={{
                      fontWeight: "400",
                      color: "#524B6B",
                      marginTop: "8px",
                    }}
                  >
                    To activate multiple features and get more views on your
                    profile, ID verification is highly recommended
                  </Text>
                  <Form
                    initialValues={{
                      otp_1: "",
                      otp_2: "",
                      otp_3: "",
                      otp_4: "",
                      otp_5: "",
                      otp_6: "",
                    }}
                    validateOnMount={true}
                    onSubmit={(values: any) => {
                      let payload = {
                        otp:
                          values.otp_1 +
                          values.otp_2 +
                          values.otp_3 +
                          values.otp_4 +
                          values.otp_5 +
                          values.otp_6,
                        ref_id: refId,
                      };
                      return new Promise((res: any) => {
                        verifyKycOTP(
                          payload,
                          (response: any) => {
                            navigate(KYCDETAIL);
                          },
                          (error: any) => {
                            handleToast(error.data.message);
                            res("");
                          }
                        );
                      });
                    }}
                    render={({
                      handleChange,
                      isValid,
                      isSubmitting,
                      submitForm,
                    }: any) => {
                      return (
                        <>
                          <Box display="flex" columnGap={2} mt={6}>
                            {[...Array(6)].map((_, index) => (
                              <TextField
                                key={index}
                                name={`otp_${index + 1}`}
                                className="otp"
                                inputProps={{ maxLength: 1 }}
                                onChange={handleChange}
                                onInput={(
                                  e: React.FormEvent<HTMLInputElement>
                                ) => {
                                  const target = e.target as HTMLInputElement;
                                  target.value = digitValidate(target.value);
                                }}
                                onKeyUp={(
                                  e: React.KeyboardEvent<HTMLInputElement>
                                ) => handleOtpChange(index, e)}
                                sx={{
                                  width: { xs: 60, md: 80 },
                                  borderRadius: "10px",
                                  textAlign: "center",
                                  color: "#fff",
                                }}
                              />
                            ))}
                          </Box>
                          {/* <Text variant="caption" color="#00C385" mt={16}>
                            Resend in 0:30 mins
                          </Text> */}
                          <Box display="flex" mt={52} columnGap={2}>
                            <Button
                              variant="outlined"
                              size="large"
                              fullWidth
                              sx={{ padding: "15px 40px", borderRadius: "8px" }}
                            >
                              Back
                            </Button>
                            <Button
                              variant="contained"
                              disabled={!isValid || isSubmitting}
                              onClick={isValid ? submitForm : () => {}}
                              size="large"
                              fullWidth
                              sx={{ padding: "15px 20px", borderRadius: "8px" }}
                              type
                            >
                              Submit
                            </Button>
                          </Box>
                        </>
                      );
                    }}
                  />
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </PrimaryLayout>
  );
};

export default VerificationOTP;
