import React from "react";
import { PrimaryLayout } from "../../Components";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Text,
  InputBox,
  CheckBox,
  Form,
  Button,
} from "@lipihipi/ui-components";
import CheckIcon from "@mui/icons-material/Check";
import { Grid, Box, CardActionArea } from "@mui/material";
import { IDVerify } from "../../Icons";
const input = {
  padding: "12px 14px",
  lineHeight: "16px",
  borderRadius: "8px",
};

const label = {
  lineHeight: "19px",
};
const LeftContent = [
  {
    title: "Your earn per view plan will be activated",
    icon: <CheckIcon sx={{ color: "#01B179", fontSize: "16px" }} />,
  },
  {
    title:
      "You will get more views on your profile as this will be on top of the recruiters search result",
    icon: <CheckIcon sx={{ color: "#01B179", fontSize: "16px" }} />,
  },
  {
    title: "AI based mock interview panel will be activated",
    icon: <CheckIcon sx={{ color: "#01B179", fontSize: "16px" }} />,
  },
  {
    title: "You will get access to all our available mentors in coming days",
    icon: <CheckIcon sx={{ color: "#01B179", fontSize: "16px" }} />,
  },
  {
    title: "You will get access to all our upcoming courses",
    icon: <CheckIcon sx={{ color: "#01B179", fontSize: "16px" }} />,
  },
  {
    title: "Technical and non technical tests panel will be activated",
    icon: <CheckIcon sx={{ color: "#01B179", fontSize: "16px" }} />,
  },
];

const BankAccountVerification = () => {
  const navigate = useNavigate();
  return (
    <PrimaryLayout>
      <Box>
        <Box sx={{ paddingTop: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={5}>
              <Card
                px={2}
                py={2}
                disableHeader
                sx={{
                  boxShadow: "none",
                }}
              >
                <Box>
                  <Text
                    variant="h3"
                    sx={{
                      pb: 1,
                      mb: 1,
                      fontWeight: "600",
                      fontSize: "20px",
                      borderBottom: "1px solid #D6DDEB",
                    }}
                  >
                    Bank account verification
                  </Text>

                  <Text variant="subtitle1" mt={2}>
                    Benefits of bank account verification
                  </Text>
                  <Box
                    display="flex"
                    justifyContent="center"
                    columnGap={3}
                    py={2}
                    alignItems="center"
                  >
                    <IDVerify />
                    <Text variant="subtitle1" textAlign="center">
                      Your Identity has
                      <br /> been{" "}
                      <Text
                        sx={{ fontWeight: 600, color: "#00C385" }}
                        component="strong"
                      >
                        verified!
                      </Text>
                    </Text>
                  </Box>
                  <Box display="flex" flexDirection="column" rowGap={2}>
                    {LeftContent.map((item, index) => (
                      <Text
                        display="flex"
                        columnGap={1}
                        sx={{
                          background: "#FCFCFC",
                          borderRadius: "8px",
                          padding: "10px 20px",
                          border: "1px solid rgba(22, 20, 88, 0.20)",
                        }}
                        variant="subtitle1"
                      >
                        {" "}
                        {item.icon} {item.title}
                      </Text>
                    ))}
                  </Box>
                </Box>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={7}>
              <Card px={3} py={3} disableHeader sx={{}}>
                <Form>
                  <Box>
                    <Box
                      display="flex"
                      columnGap={3}
                      sx={{ borderBottom: "2px solid #D9D9D9", width: "276px" }}
                    >
                      <CardActionArea
                        sx={{ width: "120px" }}
                        onClick={() => navigate("/bank-account-verification")}
                      >
                        <Text
                          variant="h3"
                          com
                          sx={{
                            mt: 1,
                            position: "relative",
                            "&::before": {
                              content: '""',
                              position: "absolute",
                              width: "110px",
                              height: "2px",
                              bottom: "-1px",
                              background: "#00C385",
                            },
                          }}
                        >
                          Basic details
                        </Text>
                      </CardActionArea>
                      <CardActionArea
                        sx={{ width: "140px" }}
                        onClick={() => navigate("/track-application")}
                      >
                        <Text variant="body2" com sx={{ mt: 1 }}>
                          Track progress
                        </Text>
                      </CardActionArea>
                    </Box>
                    <Box mt={4}>
                      <Text variant="body1" sx={{ fontWeight: "500" }}>
                        Details of the applicant
                      </Text>

                      <InputBox
                        name="Name"
                        fullWidth
                        label="Name"
                        sx={{ input, label, mt: 3 }}
                        placeholder="Entere here..."
                        variant="outlined"
                        autoFocus
                      />
                      <InputBox
                        name="Bankaccountnumber "
                        fullWidth
                        label="Bank account number"
                        sx={{ input, label, mt: 3 }}
                        placeholder="Entere here..."
                        variant="outlined"
                        autoFocus
                      />
                      <InputBox
                        name="IFSCCode"
                        fullWidth
                        label="IFSC Code"
                        sx={{ input, label, mt: 3 }}
                        placeholder="Entere here..."
                        variant="outlined"
                        autoFocus
                      />
                    </Box>

                    <Box mt={4}>
                      <Box
                        display="flex"
                        my={2}
                        justifyContent="start"
                        alignItems="flex-start"
                      >
                        <CheckBox name="TC" />
                        <Text
                          variant="caption"
                          color="#000"
                          fontWeight="400"
                          sx={{ marginLeft: "-10px" }}
                        >
                          I consent to the Terms and Conditions and allow the
                          sharing of my personal information, including ID,
                          contact details, and records, with ******** for
                          background checks. I understand that my data will be
                          kept secure and confidential, accessible only to
                          authorized parties. I can edit or delete my digital
                          record by emailing contact@******** with 'My Data, My
                          Privacy'.
                        </Text>
                      </Box>

                      <Box
                        display="flex"
                        justifyContent="start"
                        alignItems="center"
                      >
                        <CheckBox name="rupee" />
                        <Text
                          variant="caption"
                          color="#000"
                          fontWeight="400"
                          sx={{ marginLeft: "-10px" }}
                        >
                          This default verification is XXXX rupee deducted from
                          your wallet.
                        </Text>
                      </Box>
                      <Text
                        variant="caption"
                        sx={{
                          fontWeight: "400",
                          color: "#000",
                          lineHeight: "120%,",
                          display: "block",
                        }}
                      >
                        The wallet balance is low please{" "}
                        <Text
                          component="strong"
                          variant="caption"
                          sx={{ fontWeight: "700" }}
                        >
                          Top- up the wallet balance,
                        </Text>{" "}
                        kindly resumit the data however the data won’t be
                        deleted.
                      </Text>
                    </Box>

                    <Box display="flex" mt={4} columnGap={2}>
                      <Button
                        variant="outlined"
                        size="large"
                        fullWidth
                        sx={{ padding: "15px 40px", borderRadius: "8px" }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        size="large"
                        fullWidth
                        sx={{ padding: "15px 20px", borderRadius: "8px" }}
                      >
                        Submit
                      </Button>
                    </Box>
                  </Box>
                </Form>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </PrimaryLayout>
  );
};

export default BankAccountVerification;
