import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PrimaryLayout } from "../../Components";
import {
  Card,
  Text,
  InputBox,
  CheckBox,
  Form,
  Button,
} from "@lipihipi/ui-components";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Grid, Box, Chip, CardActionArea } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
const input = {
  padding: "12px 14px",
  lineHeight: "16px",
  borderRadius: "8px",
};

const label = {
  lineHeight: "19px",
};
const LeftContent = [
  {
    title: "Graduation",
    status: "VERIFIED",
    company: "BHU, Varanasi",
    icon: <CheckIcon sx={{ color: "#01B179", fontSize: "16px" }} />,
  },
  {
    title: "12th",
    status: "PENDING",
    company: "Saraswati Shishu Mandir, Varanasi",
    icon: <CheckIcon sx={{ color: "#01B179", fontSize: "16px" }} />,
  },
  {
    title: "10th",
    status: "NOT VERIFIED",
    company: "Saraswati Shishu Mandir, Varanasi",
    icon: <CheckIcon sx={{ color: "#01B179", fontSize: "16px" }} />,
  },
];

const EducationList = () => {
  const navigate = useNavigate();
  return (
    <PrimaryLayout>
      <Box>
        <Box sx={{ paddingTop: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={5}>
              <Card px={2} py={2} disableHeader>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  sx={{ pb: 2, mb: 2, borderBottom: "1px solid #D6DDEB" }}
                >
                  <Text
                    variant="h3"
                    sx={{ fontWeight: "600", fontSize: "20px" }}
                  >
                    Education record(s) (4)
                  </Text>
                </Box>

                <Box display="flex" flexDirection="column" rowGap={2}>
                  {LeftContent.map((item, index) => (
                    <Box
                      px={2}
                      py={2}
                      display="flex"
                      justifyContent="space-between"
                      sx={{
                        boxShadow: "none",
                        borderRadius: "8px",
                        background:
                          item.status === "VERIFIED"
                            ? " #F1FFF9"
                            : item.status === "PENDING"
                            ? " #F6F2FF"
                            : "rgba(255, 141, 115, 0.12)",
                      }}
                    >
                      <Box
                        display="flex"
                        columnGap={0.8}
                        sx={{ lineHeight: "110%" }}
                      >
                        {item.icon}
                        <Box>
                          <Text
                            variant="body1"
                            sx={{ fontWeight: "500", lineHeight: "120%" }}
                          >
                            {item.title}
                          </Text>
                          <Text
                            variant="caption"
                            sx={{ fontWeight: "400", lineHeight: "120%" }}
                          >
                            {item.company}
                          </Text>
                        </Box>
                      </Box>

                      <Chip
                        label={item.status}
                        sx={{
                          mt: 0,
                          fontSize: "10px",
                          padding: "6px 4px",
                          lineHeight: "10px",
                          borderRadius: "6px",
                          fontWeight: "500",
                          backgroundColor:
                            item.status === "VERIFIED"
                              ? "#CDFEF6"
                              : item.status === "PENDING"
                              ? "#EAE1FF"
                              : "rgba(255, 141, 115, 0.12);",
                          color:
                            item.status === "VERIFIED"
                              ? "#007880"
                              : item.status === "PENDING"
                              ? "#4A4458"
                              : "#F43C3C",
                        }}
                        size="small"
                      />
                    </Box>
                  ))}
                </Box>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={7}>
              <Card px={3} py={3} disableHeader sx={{}}>
                <Form>
                  <Box>
                    <Box
                      display="flex"
                      columnGap={3}
                      sx={{ borderBottom: "2px solid #D9D9D9", width: "276px" }}
                    >
                      <CardActionArea
                        sx={{ width: "120px" }}
                        onClick={() => navigate("/education-list")}
                      >
                        <Text
                          variant="h3"
                          com
                          sx={{
                            mt: 1,
                            position: "relative",
                            "&::before": {
                              content: '""',
                              position: "absolute",
                              width: "110px",
                              height: "2px",
                              bottom: "-1px",
                              background: "#00C385",
                            },
                          }}
                        >
                          Basic details
                        </Text>
                      </CardActionArea>
                      <CardActionArea
                        sx={{ width: "140px" }}
                        onClick={() => navigate("/track-application")}
                      >
                        <Text variant="body2" com sx={{ mt: 1 }}>
                          Track progress
                        </Text>
                      </CardActionArea>
                    </Box>
                    <Box mt={4}>
                      <Text variant="body1" sx={{ fontWeight: "500" }}>
                        Education Details
                      </Text>

                      <InputBox
                        name="EducationLevel"
                        fullWidth
                        label="Education Level"
                        sx={{ input, label, mt: 3 }}
                        placeholder="Entere here..."
                        variant="outlined"
                        autoFocus
                      />
                      <InputBox
                        name="Insitute Name"
                        fullWidth
                        label="Insitute Name"
                        sx={{ input, label, mt: 3 }}
                        placeholder="Entere here..."
                        variant="outlined"
                        autoFocus
                      />

                      <InputBox
                        name="Degree"
                        fullWidth
                        label="Degree   "
                        sx={{ input, label, mt: 3 }}
                        placeholder="Entere here..."
                        variant="outlined"
                        autoFocus
                      />
                      <InputBox
                        name="Registration Number"
                        fullWidth
                        label="Registration Number  "
                        sx={{ input, label, mt: 3 }}
                        placeholder="Entere here..."
                        variant="outlined"
                        autoFocus
                      />

                      <InputBox
                        name="Namedocument"
                        fullWidth
                        label="Name as per document    "
                        sx={{ input, label, mt: 3 }}
                        placeholder="Entere here..."
                        variant="outlined"
                        autoFocus
                      />
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                          <InputBox
                            name="Startingdate"
                            fullWidth
                            label="Starting date"
                            sx={{ input, label, mt: 3 }}
                            placeholder="Entere here..."
                            variant="outlined"
                            autoFocus
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <InputBox
                            name="GradePercentage"
                            fullWidth
                            label="Grade/Percentage"
                            sx={{ input, label, mt: 3 }}
                            placeholder="Entere here..."
                            variant="outlined"
                            autoFocus
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                          <InputBox
                            name="GradingType"
                            fullWidth
                            label="Grading Type"
                            sx={{ input, label, mt: 3 }}
                            placeholder="Entere here..."
                            variant="outlined"
                            autoFocus
                          />
                        </Grid>
                      </Grid>
                    </Box>

                    <Box mt={4}>
                      <Text variant="body1" mb={2} sx={{ fontWeight: "500" }}>
                        Add Document Proof
                      </Text>

                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={5}>
                          <Box
                            display="flex"
                            flexDirection="column"
                            rowGap={1.5}
                            sx={{
                              borderRadius: "20px",
                              border: "0.5px dashed #9D97B5",
                              background: "#FFF",
                              padding: "20px",
                            }}
                          >
                            <Box
                              display="flex"
                              alignItems="flex-start"
                              columnGap={1.5}
                            >
                              <PictureAsPdfIcon
                                sx={{ fontSize: "46px", color: "#FF464B" }}
                              />
                              <Box>
                                <Text variant="caption">Salary Slip</Text>
                                <Text
                                  variant="caption"
                                  color="#484949"
                                  display="block"
                                >
                                  867 Kb
                                </Text>
                              </Box>
                            </Box>
                            <Box display="flex" columnGap={1}>
                              <DeleteOutlineIcon sx={{ color: "#FF464B" }} />
                              <Box>
                                <Text variant="caption" color="#FF464B;">
                                  Remove file
                                </Text>
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={5}>
                          <Box
                            display="flex"
                            flexDirection="column"
                            rowGap={1.5}
                            sx={{
                              borderRadius: "20px",
                              border: "0.5px dashed #9D97B5",
                              background: "#FFF",
                              padding: "20px",
                            }}
                          >
                            <Box
                              display="flex"
                              alignItems="flex-start"
                              columnGap={1.5}
                            >
                              <PictureAsPdfIcon
                                sx={{ fontSize: "46px", color: "#FF464B" }}
                              />
                              <Box>
                                <Text variant="caption">Salary Slip</Text>
                                <Text
                                  variant="caption"
                                  color="#484949"
                                  display="block"
                                >
                                  867 Kb
                                </Text>
                              </Box>
                            </Box>
                            <Box display="flex" columnGap={1}>
                              <DeleteOutlineIcon sx={{ color: "#FF464B" }} />
                              <Box>
                                <Text variant="caption" color="#FF464B;">
                                  Remove file
                                </Text>
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>

                    <Box mt={4}>
                      <Box
                        display="flex"
                        my={2}
                        justifyContent="start"
                        alignItems="flex-start"
                      >
                        <CheckBox name="TC" />
                        <Text
                          variant="caption"
                          color="#000"
                          fontWeight="400"
                          sx={{ marginLeft: "-10px" }}
                        >
                          I consent to the Terms and Conditions and allow the
                          sharing of my personal information, including ID,
                          contact details, and records, with ******** for
                          background checks. I understand that my data will be
                          kept secure and confidential, accessible only to
                          authorized parties. I can edit or delete my digital
                          record by emailing contact@******** with 'My Data, My
                          Privacy'.
                        </Text>
                      </Box>

                      <Box
                        display="flex"
                        justifyContent="start"
                        alignItems="center"
                      >
                        <CheckBox name="rupee" />
                        <Text
                          variant="caption"
                          color="#000"
                          fontWeight="400"
                          sx={{ marginLeft: "-10px" }}
                        >
                          This default verification is XXXX rupee deducted from
                          your wallet.
                        </Text>
                      </Box>
                      <Text
                        variant="caption"
                        sx={{
                          fontWeight: "400",
                          color: "#000",
                          lineHeight: "120%,",
                          display: "block",
                        }}
                      >
                        The wallet balance is low please{" "}
                        <Text
                          component="strong"
                          variant="caption"
                          sx={{ fontWeight: "700" }}
                        >
                          Top- up the wallet balance,
                        </Text>{" "}
                        kindly resumit the data however the data won’t be
                        deleted.
                      </Text>
                    </Box>

                    <Box display="flex" mt={4} columnGap={2}>
                      <Button
                        variant="outlined"
                        size="large"
                        fullWidth
                        sx={{ padding: "15px 40px", borderRadius: "8px" }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        size="large"
                        fullWidth
                        sx={{ padding: "15px 20px", borderRadius: "8px" }}
                      >
                        Submit
                      </Button>
                    </Box>
                  </Box>
                </Form>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </PrimaryLayout>
  );
};

export default EducationList;
