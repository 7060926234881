import { SecondaryLayout } from "../../Components";
import { Box, Grid } from "@mui/material";
import { Button, Card, CustomTable, Text } from "@lipihipi/ui-components";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import TopupModal from "../../Components/Modal/Topup";
import { AppContext } from "../../App";
import { ADD_UPI } from "../../RouteConstant";
import { getTranscationList } from "../../Services/Service";
import IdVerificationModal from "../../Components/Modal/IdVerification";

const WalletListing = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openIdVerification, setIdVerification] = useState(false);
  const { state } = useContext<any>(AppContext);
  const [transactionList, setTransactionList] = useState([]);
  useEffect(() => {
    getTranscationList((res: any) => {
      setTransactionList(res.data.data);
    });
  }, []);

  const tableHeadings = [
    { id: 0, label: "Transcation Type" },
    { id: 1, label: "Description" },
    { id: 2, label: "Amount" },
    { id: 3, label: "Date on" },
  ];
  const handleClick = () => {
    if (state.user.verified_user === "1") {
      navigate(ADD_UPI);
    } else {
      setIdVerification(true);
    }
  };
  return (
    <>
      <SecondaryLayout currentActive={12}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Text variant="h1">Wallet</Text>
            <Text variant="body1" mt={0.4}>
              Program of referral can make you Rs. 5000.
            </Text>
          </Box>
        </Box>

        <Box mt={3} mb={5}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <Card
                px={2}
                sx={{
                  boxShadow: "none",
                  border: "1px solid rgba(57, 57, 63, 0.10)",
                }}
                disableHeader
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  rowGap={2}
                  sx={{
                    background: "#00c385",
                    borderRadius: "10px",
                    padding: "30px 20px",
                    width: "100%",
                  }}
                >
                  <Text variant="caption" color="#fff">
                    Wallet Balance
                  </Text>
                  <Text variant="h1" color="#fff">
                    {state.user.wallet.balance}{" "}
                    <Text variant="caption" component="strong">
                      Points
                    </Text>
                  </Text>
                </Box>
                <Box display="flex" flexDirection="column" rowGap={2} mt={1}>
                  <Button
                    variant="contained"
                    sx={{
                      background: "#161458",
                      padding: "20px 8px",
                      borderRadius: "6px",
                    }}
                    onClick={() => setOpen(true)}
                  >
                    <ArrowUpwardIcon sx={{ fontSize: "16px" }} />
                    Top up
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{ padding: "20px 8px", borderRadius: "6px" }}
                    onClick={handleClick}
                  >
                    Reedem
                  </Button>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
              {/* <Box src={Graph} component="img" sx={{ width: "100%" }}></Box> */}
            </Grid>
          </Grid>
        </Box>

        <Box display="flex" flexDirection="column" rowGap={2}>
          <Card
            heading="Recent Transactions"
            // action={{ text: "See All Transactions" }}
            disableAction={true}
          >
            <Box mx={-4}>
              <CustomTable heading={tableHeadings} data={transactionList} />
            </Box>
          </Card>

          <TopupModal open={open} onClose={setOpen} />
          <IdVerificationModal
            open={openIdVerification}
            onClose={setIdVerification}
          />
        </Box>
      </SecondaryLayout>
    </>
  );
};

export default WalletListing;
