import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  CardActionArea,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  colors,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { PrimaryLayout } from "../../../Components";
import { Card, Text, InputBox, Form, Button } from "@lipihipi/ui-components";
import { Grid } from "@mui/material";
import { IDVerify } from "../../../Icons";
import { sendKycOTP } from "../../../Services/Service";
import handleToast, { success } from "../../../util/Toast";
import { AppContext } from "../../../App";
import InsufficientFundModal from "../../../Components/Modal/InsufficientFund";
import { useNavigate } from "react-router-dom";
import { KYCDETAIL, KYCOTP } from "../../../RouteConstant";

export const LeftContent = [
  {
    title: "Your earn per view plan will be activated",
    icon: <CheckIcon sx={{ color: "#01B179", fontSize: "16px" }} />,
  },
  {
    title:
      "You will get more views on your profile as this will be on top of the recruiters search result",
    icon: <CheckIcon sx={{ color: "#01B179", fontSize: "16px" }} />,
  },
  {
    title: "AI based mock interview panel will be activated",
    icon: <CheckIcon sx={{ color: "#01B179", fontSize: "16px" }} />,
  },
  {
    title: "You will get access to all our available mentors in coming days",
    icon: <CheckIcon sx={{ color: "#01B179", fontSize: "16px" }} />,
  },
  {
    title: "You will get access to all our upcoming courses",
    icon: <CheckIcon sx={{ color: "#01B179", fontSize: "16px" }} />,
  },
  {
    title: "Technical and non technical tests panel will be activated",
    icon: <CheckIcon sx={{ color: "#01B179", fontSize: "16px" }} />,
  },
];
const IDVerification = () => {
  const { state } = useContext<any>(AppContext);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate()
  useEffect(()=>{
    if(state.user.verified_user === 1){
      navigate(KYCDETAIL)
    }
  },[])
  const handleSubmit = (values: any) => {
   
    return new Promise((res: any) => {
      if (state.user.wallet.balance < 99) {
        setOpen(true);
        res("");
      }
      sendKycOTP(
        values,
        (response: any) => {
          navigate(KYCOTP.replace(':refId',response.data.ref_id))
        },
        (error: any) => {
          handleToast(error.data.message);
          res("");
        }
      );
    });
  };
  return (
    <PrimaryLayout>
      <Box>
        <Box sx={{ paddingTop: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={5}>
              <Card
                px={2}
                py={2}
                disableHeader
                sx={{
                  boxShadow: "none",
                }}
              >
                <Box>
                  <Text
                    variant="h3"
                    sx={{
                      pb: 1,
                      mb: 1,
                      fontWeight: "600",
                      fontSize: "20px",
                      borderBottom: "1px solid #D6DDEB",
                    }}
                  >
                    ID verification
                  </Text>

                  <Text variant="subtitle1">
                    Benefits of ID verification at LIPIJobs
                  </Text>
                  <Box
                    display="flex"
                    justifyContent="center"
                    columnGap={3}
                    py={2}
                    alignItems="center"
                  >
                    <IDVerify />
                    <Text variant="subtitle1" textAlign="center">
                      Your Identity has
                      <br /> been{" "}
                      <Text
                        sx={{ fontWeight: 600, color: "#00C385" }}
                        component="strong"
                      >
                        verified!
                      </Text>
                    </Text>
                  </Box>
                  <Box display="flex" flexDirection="column" rowGap={2}>
                    {LeftContent.map((item, index) => (
                      <Text
                        display="flex"
                        columnGap={1}
                        sx={{
                          background: "#FCFCFC",
                          borderRadius: "8px",
                          padding: "10px 20px",
                          border: "1px solid rgba(22, 20, 88, 0.20)",
                        }}
                        variant="subtitle1"
                      >
                        {" "}
                        {item.icon} {item.title}
                      </Text>
                    ))}
                  </Box>
                </Box>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={7}>
              <Card px={3} py={3} disableHeader sx={{}}>
                <Box>
                  <Text variant="h1" com sx={{ mt: 1, color: "#25324B" }}>
                    Enter your Aadhaar number
                  </Text>

                  <Text
                    variant="subtitle2"
                    sx={{
                      fontWeight: "400",
                      color: "#524B6B",
                      marginTop: "8px",
                    }}
                  >
                    To activate multiple features and get more views on your
                    profile, ID verification is highly recommended
                  </Text>

                  <Form
                    initialValues={{ aadhaar_number: "" }}
                    validateOnMount={true}
                    onSubmit={handleSubmit}
                    render={({
                      handleChange,
                      isValid,
                      isSubmitting,
                      submitForm,
                    }: any) => {
                      return (
                        <>
                          <InputBox
                            name="aadhaar_number"
                            fullWidth
                            label="Enter Aadhaar number"
                            sx={{ mt: 4 }}
                            placeholder="Entere here..."
                            variant="outlined"
                            autoFocus
                            onChange={handleChange}
                          />
                          {/* <Text variant="caption">Powered by</Text> */}

                          <Box display="flex" mt={52} columnGap={2}>
                            <Button
                              variant="outlined"
                              size="large"
                              fullWidth
                              sx={{ padding: "15px 40px", borderRadius: "8px" }}
                            >
                              Back
                            </Button>
                            <Button
                              variant="contained"
                              disabled={!isValid || isSubmitting}
                              onClick={isValid ? submitForm : () => {}}
                              size="large"
                              fullWidth
                              sx={{ padding: "15px 20px", borderRadius: "8px" }}
                            >
                              Submit
                            </Button>
                          </Box>
                        </>
                      );
                    }}
                  />
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <InsufficientFundModal open={open} onClose={setOpen} />
    </PrimaryLayout>
  );
};

export default IDVerification;
