import { Box, Dialog } from "@mui/material";
import { Text, Button } from "@lipihipi/ui-components";
import { Congrat } from "../../Icons";
import { useNavigate } from "react-router-dom";
import { WALLET } from "../../RouteConstant";

type Props = {
  open: boolean;
  onClose: any;
};
const PayoutTransferModal = ({ open, onClose }: Props) => {
  const navigate = useNavigate();
  return (
    <>
      <Box>
        <Dialog open={open} onClose={onClose}>
          <Box p={4} textAlign="center" width="400px">
            <Congrat />
            <Box py={3}>
              <Text variant="h1">Money Transferred Successfully </Text>
              <Text variant="body1" pt={1}>
                The amount has been successfully transferred to the UPI ID you
                provided. Thank you for using the Lipijob wallet!
              </Text>
            </Box>

            <Button
              variant="outlined"
              size="large"
              sx={{ padding: "15px 60px", borderRadius: "8px" }}
              onClick={() => navigate(WALLET)}
            >
              Go TO WALLET
            </Button>
          </Box>
        </Dialog>
      </Box>
    </>
  );
};

export default PayoutTransferModal;
