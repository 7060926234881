import * as routePaths from "./RouteConstant";
import {
  GeneralInfo,
  LoginPage,
  OTP,
  Password,
  Profile,
  EditBasicDetails,
  EditSelfIntroduction,
  EditHobbyDetails,
  EditSkills,
  EditJob,
  Subscription,
  Refer,
  Events,
  Resume,
  Mock,
  Jobs,
  JobDetails,
  JobSearch,
  Education,
  Experience,
  ResumePreview,
  ListingPage,
  IDVerification,
  VerificationOTP,
  VerificationSuccess,
  Employment,
  EmploymentView,
  EducationList,
  AddressVerification,
  BankAccountVerification,
  CreditCheck,
  ProfessionalReferenceCheck,
  TrackApplication,
  WalletListing,
  AddUPI,
  RedeemAmount
} from "./Pages";
import AddPhone from "./Pages/GeneralInformation/AddPhone";
import MobileOTP from "./Pages/Otp/mobile-otp";
import { Navigate, RouteObject } from "react-router-dom";
import PageNotFound from "./Components/NotFound";
import AddEditProject from "./Pages/Profile/Project/AddEdit";
import { useContext } from "react";
import { AppContext } from "./App";
import ForgotPassword from "./Pages/ForgotPassword";
import SaveInterviewResult from "./Pages/Mock/save-interview";
import { ActionType } from "./Reducer";
import AccessDenied from "./Pages/AccessDenied";
import AppliedJobs from "./Pages/Jobs/AppliedJob";
import SavedJobs from "./Pages/Jobs/SaveForLater";
import JsonViewer from "./Pages/JsonViewer";
import IosJsonViewer from "./Pages/IosJsonViewer";
import Notification from "./Components/Notifications";

const AuthRoutes = ({ children, verified = true }: any) => {
  const { state, dispatch } = useContext<any>(AppContext);
  const isLoggedIn = !!state?.user;
  const isLoading = state?.page_loading;
  if (!isLoggedIn) {
    return <Navigate to={routePaths.LOGIN} />;
  }
  if (state?.user?.user_type !== 2) {
    localStorage.removeItem("token");
    dispatch({
      type: ActionType.LOGOUT,
      payload: "",
    });
    return <Navigate to={routePaths.ACCESS_DENIED} />;
  }
  console.log(state);
  if (verified) {
    if (!state?.user?.verified) {
      return <Navigate to={routePaths.OTP} />;
    }
    if (!state?.user?.fname || !state?.user?.gender) {
      return <Navigate to={routePaths.GENERAL_INFO} />;
    }
    if (!state?.user?.contact_no) {
      return <Navigate to={routePaths.ADD_PHONE} />;
    }
    if (state?.user?.verify_mobile === 0) {
      return <Navigate to={routePaths.MOBILE_OTP} />;
    }
  }

  return <>{!isLoading ? children : null}</>;
};
const PublicRoutes = ({ children }: any) => {
  const { state } = useContext<any>(AppContext);
  const isLoggedIn = !!state?.user;
  const isLoading = state?.page_loading;

  if (isLoggedIn) {
    return <Navigate to={routePaths.DASHBOARD} />;
  }

  return <>{!isLoading ? children : null}</>;
};

export const routes: RouteObject[] = [
  {
    path: "/",
    children: [
      {
        path: routePaths.DASHBOARD,
        element: (
          <AuthRoutes>
            <Navigate to={routePaths.PROFILE} />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.NOTIFICATION,
        element: (
          <AuthRoutes>
            <Notification />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.LOGIN,
        element: (
          <PublicRoutes>
            <LoginPage />
          </PublicRoutes>
        ),
      },
      {
        path: routePaths.ASSETLINKSJSON,
        element: (
          <PublicRoutes>
            <JsonViewer />
          </PublicRoutes>
        ),
      },
      {
        path: routePaths.ASSETLINKSIOSJSON,
        element: (
          <PublicRoutes>
            <IosJsonViewer />
          </PublicRoutes>
        ),
      },
      {
        path: routePaths.FORGOT_PASSWORD,
        element: (
          <PublicRoutes>
            <ForgotPassword />
          </PublicRoutes>
        ),
      },
      {
        path: routePaths.SAVE_INTERVIEW_RESULT,
        element: (
          // <PublicRoutes>
          <SaveInterviewResult />
          // </PublicRoutes>
        ),
      },
      {
        path: routePaths.ACCESS_DENIED,
        element: (
          <PublicRoutes>
            <AccessDenied />
          </PublicRoutes>
        ),
      },
      {
        path: routePaths.PASSWORD,
        element: (
          <PublicRoutes>
            <Password />
          </PublicRoutes>
        ),
      },
      {
        path: routePaths.PROFILE,
        element: (
          <AuthRoutes>
            <Profile />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.OTP,
        element: (
          <AuthRoutes verified={false}>
            <OTP />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.MOBILE_OTP,
        element: (
          <AuthRoutes verified={false}>
            <MobileOTP />
          </AuthRoutes>
        ),
      },

      {
        path: routePaths.GENERAL_INFO,
        element: (
          <AuthRoutes verified={false}>
            <GeneralInfo />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.ADD_PHONE,
        element: (
          <AuthRoutes verified={false}>
            <AddPhone />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.BASIC_DETAILS,
        element: (
          <AuthRoutes>
            <EditBasicDetails />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.SELF_INTRODUCTION,
        element: (
          <AuthRoutes>
            <EditSelfIntroduction />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.HOBBY,
        element: (
          <AuthRoutes>
            <EditHobbyDetails />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.SKILLS,
        element: (
          <AuthRoutes>
            <EditSkills />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.JOB,
        element: (
          <AuthRoutes>
            <EditJob />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.SUBSCRIPTION,
        element: (
          <AuthRoutes>
            <Subscription />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.REFER,
        element: (
          <AuthRoutes>
            <Refer />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.EVENTS,
        element: (
          <AuthRoutes>
            <Events />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.RESUME,
        element: (
          <AuthRoutes>
            <Resume />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.MOCK,
        element: (
          <AuthRoutes>
            <Mock />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.MY_JOBS,
        element: (
          <AuthRoutes>
            <Jobs />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.APPLIED_JOBS,
        element: (
          <AuthRoutes>
            <AppliedJobs />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.SAVED_JOBS,
        element: (
          <AuthRoutes>
            <SavedJobs />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.JOBS_SEARCH,
        element: (
          <AuthRoutes>
            <JobSearch />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.JOBS_DETAILS,
        element: (
          <AuthRoutes>
            <JobDetails />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.EDUCATION,
        element: (
          <AuthRoutes>
            <Education />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.EDIT_EDUCATION,
        element: (
          <AuthRoutes>
            <Education />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.EXPERIENCE,
        element: (
          <AuthRoutes>
            <Experience />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.EDIT_EXPERIENCE,
        element: (
          <AuthRoutes>
            <Experience />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.PROJECT,
        element: (
          <AuthRoutes>
            <AddEditProject />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.EDIT_PROJECT,
        element: (
          <AuthRoutes>
            <AddEditProject />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.RESUME_PREVIEW,
        element: (
          <AuthRoutes>
            <ResumePreview />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.BGVLISTING,
        element: (
          <AuthRoutes>
            <ListingPage />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.IDVerification,
        element: (
          <AuthRoutes>
            <IDVerification />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.KYCOTP,
        element: (
          <AuthRoutes>
            <VerificationOTP />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.KYCDETAIL,
        element: (
          <AuthRoutes>
            <VerificationSuccess />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.Employment,
        element: (
          <AuthRoutes>
            <Employment />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.EmploymentView,
        element: (
          <AuthRoutes>
            <EmploymentView />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.EducationList,
        element: (
          <AuthRoutes>
            <EducationList />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.AddressVerification,
        element: (
          <AuthRoutes>
            <AddressVerification />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.AddressVerification,
        element: (
          <AuthRoutes>
            <AddressVerification />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.BankAccountVerification,
        element: (
          <AuthRoutes>
            <BankAccountVerification />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.CreditCheck,
        element: (
          <AuthRoutes>
            <CreditCheck />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.ProfessionalReferenceCheck,
        element: (
          <AuthRoutes>
            <ProfessionalReferenceCheck />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.TrackApplication,
        element: (
          <AuthRoutes>
            <TrackApplication />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.WALLET,
        element: (
          <AuthRoutes>
            <WalletListing />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.ADD_UPI,
        element: (
          <AuthRoutes>
            <AddUPI />
          </AuthRoutes>
        ),
      },
      {
        path: routePaths.REDEEM_AMOUNT,
        element: (
          <AuthRoutes>
            <RedeemAmount />
          </AuthRoutes>
        ),
      },
    ],
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
];
