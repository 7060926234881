import { Box } from "@mui/material";
import { PrimaryLayout } from "../../../Components";
import { Card, Text, Button } from "@lipihipi/ui-components";
import { Grid } from "@mui/material";
import { Congrat, UserIcon } from "../../../Icons";
import { useEffect, useState } from "react";
import { getKyc } from "../../../Services/Service";
const VerificationSuccess = () => {
  const [kyc, setKyc] = useState<any>();
  useEffect(() => {
    getKyc((response: any) => {
      setKyc(response?.data);
    });
  },[]);
  return (
    <PrimaryLayout>
      <Box>
        <Box sx={{ paddingTop: 3 }}>
          <Grid justifyContent="center" container spacing={2}>
            <Grid item xs={12} sm={6} md={7}>
              <Card px={3} py={3} disableHeader sx={{}}>
                <Box px={16} py={4} textAlign="center">
                  <Congrat />
                  <Box py={2}>
                    <Text variant="h1" com sx={{ mt: 1, color: "#25324B" }}>
                      Congratulations! Your ID has been verified
                    </Text>

                    <Text
                      variant="subtitle2"
                      sx={{
                        fontWeight: "400",
                        color: "#524B6B",
                        marginTop: "8px",
                      }}
                    >
                      As KYC we have fetched your below details and added with
                      us for further transections
                    </Text>
                  </Box>

                  <Box
                    display="flex"
                    alignItems="self-start"
                    columnGap={2}
                    sx={{
                      border: "1px solid rgba(0, 0, 0, 0.10)",
                      padding: "10px",
                      borderRadius: "10px",
                    }}
                  >
                    <UserIcon />
                    <Box textAlign="left">
                      <Text variant="body1">
                        Name:{" "}
                        <Text component="strong" sx={{ fontWeight: "600" }}>
                          {kyc?.name}
                        </Text>
                      </Text>
                      <Text variant="caption">Address:{kyc?.address}</Text>
                      <Text variant="caption" display="block" mt={1}>
                        Gender: {kyc?.gender}
                      </Text>
                    </Box>
                  </Box>
                  <Button
                    variant="contained"
                    size="large"
                    fullWidth
                    sx={{ borderRadius: "8px", marginTop: "36px" }}
                  >
                    Go back to Verification Dashboard
                  </Button>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </PrimaryLayout>
  );
};

export default VerificationSuccess;
