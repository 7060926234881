import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, CardActionArea, Dialog, Grid, Chip } from "@mui/material";
import { SecondaryLayout } from "../../../Components";
import { Card, Text, InputBox, Form, Button } from "@lipihipi/ui-components";
import { verifications } from "./ListingData";
const ListingPage = () => {
  const navigate = useNavigate();

  return (
    <SecondaryLayout currentActive={0}>
      <Box>
        <Text variant="h3">Background Verification List</Text>
        <Text variant="caption">
          These are list of verifications, that helps recruiter choose your
          profile quicker.
        </Text>
        <Box sx={{ paddingTop: 3 }}>
          <Grid container spacing={2}>
            {verifications.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <CardActionArea onClick={() => navigate(item.link)}>
                  <Card
                    px={1}
                    py={2}
                    disableHeader
                    sx={{
                      boxShadow: "none",
                      height: "190px",
                      background:
                        item.status === "VERIFIED"
                          ? " #F1FFF9"
                          : item.status === "PENDING"
                          ? " #F6F2FF"
                          : "#fff",
                      border:
                        item.status === "VERIFIED"
                          ? "2px solid #00C385"
                          : item.status === "PENDING"
                          ? "2px solid #BFACEB"
                          : "2px solid #fff",
                    }}
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box display="flex" alignItems="center" columnGap={1}>
                        {item.icon}
                        <Text
                          variant="caption"
                          sx={{ mt: 1, fontWeight: "400", fontSize: "13px" }}
                        >
                          {item.count}
                        </Text>
                      </Box>
                      <Chip
                        label={item.status}
                        sx={{
                          mt: 0,
                          fontSize: "10px",
                          padding: "6px 10px",
                          lineHeight: "10px",
                          borderRadius: "6px",
                          fontWeight: "500",
                          backgroundColor:
                            item.status === "VERIFIED"
                              ? "#CDFEF6"
                              : item.status === "PENDING"
                              ? "#EAE1FF"
                              : "rgba(255, 141, 115, 0.12);",
                          color:
                            item.status === "VERIFIED"
                              ? "#007880"
                              : item.status === "PENDING"
                              ? "#4A4458"
                              : "#F43C3C",
                        }}
                        size="small"
                      />
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      rowGap={0.6}
                      mt={2}
                    >
                      <Text variant="h3">{item.title}</Text>
                      <Text variant="caption" sx={{ fontSize: "13px" }}>
                        {item.description}
                      </Text>
                      <Text variant="caption" display="block" sx={{ mt: 1 }}>
                        {item.price}
                      </Text>
                    </Box>
                  </Card>
                </CardActionArea>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </SecondaryLayout>
  );
};

export default ListingPage;
