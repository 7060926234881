import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PrimaryLayout } from "../../Components";
import {
  Card,
  Text,
  InputBox,
  CheckBox,
  Form,
  Button,
} from "@lipihipi/ui-components";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Grid, Box, Chip, CardActionArea } from "@mui/material";
import { Pluse, Tick } from "../../Icons";
import { Datepicker } from "../../Shared/Datepicker";
const input = {
  padding: "12px 14px",
  lineHeight: "16px",
};

const label = {
  lineHeight: "19px",
};
const LeftContent = [
  {
    title: "Senior branch manager International sales",
    status: "VERIFIED",
    company: "Amazon India ltd.",
    icon: <CheckIcon sx={{ color: "#01B179", fontSize: "16px" }} />,
  },
  {
    title: "Senior sales manager",
    status: "PENDING",
    company: "Amazon India ltd.",
    icon: <CheckIcon sx={{ color: "#01B179", fontSize: "16px" }} />,
  },
  {
    title: "Senior sales manager",
    status: "NOT VERIFIED",
    company: "Amazon India ltd.",
    icon: <CheckIcon sx={{ color: "#01B179", fontSize: "16px" }} />,
  },
];

const TrackApplication = () => {
  const navigate = useNavigate();
  return (
    <PrimaryLayout>
      <Box>
        <Box sx={{ paddingTop: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={5}>
              <Card px={2} py={2} disableHeader>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  sx={{ pb: 2, mb: 2, borderBottom: "1px solid #D6DDEB" }}
                >
                  <Text
                    variant="h3"
                    sx={{ fontWeight: "600", fontSize: "20px" }}
                  >
                    Employment record(s) (18)
                  </Text>
                  <Button
                    variant="outlined"
                    sx={{ borderRadius: "8px", padding: "8px" }}
                  >
                    <Pluse />
                    Add New
                  </Button>
                </Box>

                <Box display="flex" flexDirection="column" rowGap={2}>
                  {LeftContent.map((item, index) => (
                    <Box
                      px={2}
                      py={2}
                      display="flex"
                      justifyContent="space-between"
                      sx={{
                        boxShadow: "none",
                        borderRadius: "8px",
                        background:
                          item.status === "VERIFIED"
                            ? " #F1FFF9"
                            : item.status === "PENDING"
                            ? " #F6F2FF"
                            : "rgba(255, 141, 115, 0.12)",
                      }}
                    >
                      <Box
                        display="flex"
                        columnGap={0.8}
                        sx={{ lineHeight: "110%" }}
                      >
                        {item.icon}
                        <Box>
                          <Text
                            variant="body1"
                            sx={{ fontWeight: "500", lineHeight: "120%" }}
                          >
                            {item.title}
                          </Text>
                          <Text
                            variant="caption"
                            sx={{ fontWeight: "400", lineHeight: "120%" }}
                          >
                            {item.company}
                          </Text>
                        </Box>
                      </Box>

                      <Chip
                        label={item.status}
                        sx={{
                          mt: 0,
                          fontSize: "10px",
                          padding: "6px 4px",
                          lineHeight: "10px",
                          borderRadius: "6px",
                          fontWeight: "500",
                          backgroundColor:
                            item.status === "VERIFIED"
                              ? "#CDFEF6"
                              : item.status === "PENDING"
                              ? "#EAE1FF"
                              : "rgba(255, 141, 115, 0.12);",
                          color:
                            item.status === "VERIFIED"
                              ? "#007880"
                              : item.status === "PENDING"
                              ? "#4A4458"
                              : "#F43C3C",
                        }}
                        size="small"
                      />
                    </Box>
                  ))}
                </Box>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={7}>
              <Card px={3} py={3} disableHeader sx={{}}>
                <Form>
                  <Box>
                    <Box
                      display="flex"
                      columnGap={3}
                      sx={{ borderBottom: "2px solid #D9D9D9", width: "276px" }}
                    >
                      <CardActionArea
                        sx={{ width: "120px" }}
                        onClick={() => navigate("/education-list")}
                      >
                        <Text variant="body2" com sx={{ mt: 1 }}>
                          Basic details
                        </Text>
                      </CardActionArea>
                      <CardActionArea
                        sx={{ width: "140px" }}
                        onClick={() => navigate("/track-application")}
                      >
                        <Text
                          variant="h3"
                          sx={{
                            mt: 1,
                            position: "relative",
                            "&::before": {
                              content: '""',
                              position: "absolute",
                              width: "110px",
                              height: "2px",
                              bottom: "-1px",
                              background: "#00C385",
                            },
                          }}
                        >
                          Track progress
                        </Text>
                      </CardActionArea>
                    </Box>

                    <Box mt={4}>
                      <Text
                        variant="h1"
                        sx={{ fontWeight: "700", color: "#25324B" }}
                      >
                        Senior branch manager International sales for cargo
                        business
                      </Text>
                      <Text variant="caption">Amazon</Text>

                      <Box m={3}>
                        <Text variant="body1">Application Tracking</Text>
                        <Box sx={{ borderLeft: "1px solid #049B6B" }}>
                          <Box sx={{ marginLeft: "-13px" }}>
                            <Box>
                              <Box display="flex" mb={1} columnGap={1} mt={1.4}>
                                <CheckIcon
                                  sx={{
                                    background: "#049B6B",
                                    color: "#fff",
                                    padding: "2px",
                                    borderRadius: "50%",
                                  }}
                                />
                                <Text variant="body1">
                                  Applied on 13 Mar, 24
                                </Text>
                              </Box>
                              <Box
                                display="flex"
                                flexDirection="column"
                                rowGap={1}
                                sx={{ marginLeft: "33px" }}
                              >
                                <Text
                                  variant="caption"
                                  display="flex"
                                  alignItems="center"
                                  columnGap={0.6}
                                >
                                  <FileCopyIcon
                                    sx={{ color: "#FA3E3E", fontSize: "20px" }}
                                  />{" "}
                                  Final_rishab_marksheet.pdf
                                </Text>
                                <Text
                                  variant="caption"
                                  display="flex"
                                  alignItems="center"
                                  columnGap={0.6}
                                >
                                  <FileCopyIcon
                                    sx={{ color: "#FA3E3E", fontSize: "20px" }}
                                  />{" "}
                                  Final_rishab_marksheet.pdf
                                </Text>
                              </Box>
                            </Box>
                            <Box>
                              <Box display="flex" mb={2} columnGap={1} mt={1.4}>
                                <CheckIcon
                                  sx={{
                                    background: "#049B6B",
                                    color: "#fff",
                                    padding: "2px",
                                    borderRadius: "50%",
                                  }}
                                />
                                <Text variant="body1">
                                  Verification stated on 14 Mar, 24
                                </Text>
                              </Box>
                            </Box>
                            <Box>
                              <Box display="flex" columnGap={1} mt={1.4}>
                                <ClearIcon
                                  sx={{
                                    background: "#FA3E3E",
                                    color: "#fff",
                                    padding: "2px",
                                    borderRadius: "50%",
                                  }}
                                />
                                <Text variant="body1">
                                  Provided document rejected on 16 Mar, 24
                                </Text>
                              </Box>
                              <Box
                                display="flex"
                                flexDirection="column"
                                mt={1}
                                rowGap={1}
                                sx={{ marginLeft: "33px" }}
                              >
                                <Text
                                  variant="caption"
                                  display="flex"
                                  alignItems="center"
                                  columnGap={0.6}
                                >
                                  <FileCopyIcon
                                    sx={{ color: "#FA3E3E", fontSize: "20px" }}
                                  />{" "}
                                  Final_rishab_marksheet.pdf
                                </Text>
                                <Text
                                  variant="caption"
                                  sx={{
                                    border: "1px solid #E5E5E5;",
                                    padding: "10px",
                                    borderRadius: "10px",
                                  }}
                                >
                                  <strong>Reason:</strong>When you add an ID
                                  document that contains an address, we send
                                  that address along with other details from
                                  your document to an identity verification
                                  provider, asking them to confirm whether your
                                  address matches their records. If it does not,
                                  the check will fail.
                                </Text>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>

                      <Button variant="contained" size="large" fullWidth>
                        Resubmit
                      </Button>
                    </Box>
                  </Box>
                </Form>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </PrimaryLayout>
  );
};

export default TrackApplication;
