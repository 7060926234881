export const DASHBOARD = "/";
export const LOGIN = "/login";
export const OTP = "/otp";
export const MOBILE_OTP = "/mobile-otp";
export const ADD_PHONE = "/add-phone";
export const PASSWORD = "/password/reset/:token";
export const GENERAL_INFO = "/info";
export const PROFILE = "/profile";
export const BASIC_DETAILS = "/basic-details";
export const SELF_INTRODUCTION = "/self-introduction";
export const HOBBY = "/hobby";
export const SKILLS = "/skills";
export const JOB = "/job";
export const SUBSCRIPTION = "/subscription";

export const REFER = "/refer";
export const EVENTS = "/events";
export const RESUME = "/resume-templates";
export const MOCK = "/mock-interviews";
export const MY_JOBS = "/my-jobs";
export const APPLIED_JOBS = "/applied-jobs";
export const SAVED_JOBS = "/saved-jobs";
export const JOBS_SEARCH = "/jobs/search";
export const JOBS_DETAILS = "/detail/:jobId";
export const EDUCATION = "/education/";
export const EDIT_EDUCATION = "/education/edit/:id";
export const PROJECT = "/project/";
export const EDIT_PROJECT = "/project/edit/:id";
export const EXPERIENCE = "/experience/";
export const EDIT_EXPERIENCE = "/experience/edit/:id";
export const RESUME_PREVIEW = "/preview/:previewId";
export const FORGOT_PASSWORD = "/password/email";
export const SAVE_INTERVIEW_RESULT = "/mock-interviews/save";
export const ACCESS_DENIED = "/access-denied";

export const THANKYOU_PAGE = "/thank-you"
export const ASSETLINKSJSON = "/.well-known/assetlinks.json";
export const ASSETLINKSIOSJSON = "/.well-known/apple-app-site-association";

export const BGVLISTING = "/bg-verification";
export const IDVerification = "/bg-verification/kyc";
export const KYCOTP = "/bg-verification/kyc/otp/:refId";
export const KYCDETAIL = "/bg-verification/kyc/detail";

export const Employment = "/employment";
export const EmploymentView = "/employment-view";
export const EducationList = "/education-list";
export const AddressVerification = "/address-verification";
export const BankAccountVerification = "/bank-account-verification";
export const CreditCheck = "/credit-check";
export const ProfessionalReferenceCheck = "/professional-reference-check";
export const TrackApplication = "/track-application";
export const WALLET = "/wallet";
export const ADD_UPI = "/wallet/add-upi";
export const REDEEM_AMOUNT = "/wallet/redeem/:id";
export const NOTIFICATION = "/notification"
