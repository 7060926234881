export const LOGIN = "LOGIN";
export const OTP = "OTP";
export const PASSWORD = "PASSWORD";
export const GENERAL_INFO = "GENERAL_INFO";
export const PROFILE = "PROFILE";
export const BASIC_DETAILS = "BASIC_DETAILS";
export const SELF_INTRODUCTION = "SELF_INTRODUCTION";
export const HOBBY = "HOBBY";
export const SKILLS = "SKILLS";
export const JOB = "JOB";
export const KYC = "KYC";
export const SUBSCRIPTION = "SUBSCRIPTION";
export const REFER = "REFER";
export const EVENTS = "EVENTS";
export const RESUME = "RESUME";
export const MOCK = "MOCK";
export const MY_JOBS = "MY_JOBS";
export const APPLIED_JOBS = "APPLIED_JOBS";
export const SAVED_JOBS = "SAVED_JOBS";
export const JOBS_SEARCH = "JOBS_SEARCH";
export const JOBS_DETAILS = "JOBS_DETAILS";
export const EDUCATION = "EDUCATION";
export const EXPERIENCE = "EXPERIENCE";
export const RESUME_PREVIEW = "RESUME_PREVIEW";
export const ADD_PHONE = "ADD_PHONE";
export const MOBILE_OTP = "MOBILE_OTP";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const SAVE_INTERVIEW_RESULT = "SAVE_INTERVIEW_RESULT";
export const EDIT_EDUCATION = "EDIT_EDUCATION";
export const PROJECT = "PROJECT";
export const EDIT_PROJECT = "EDiT_PROJECT";
export const EDIT_EXPERIENCE = "EDiT_PROJECT";
export const LISTINGPAGE = "LISTINGPAGE";
export const IDVerification = "IDVerification";
export const VerificationOTP = "VerificationOTP";
export const VerificationSuccess = "VerificationSuccess";
export const Employment = "Employment";
export const EmploymentView = "EmploymentView";
export const EducationList = "EducationList";
export const AddressVerification = "AddressVerification";
export const BankAccountVerification = "BankAccountVerification";
export const CreditCheck = "CreditCheck";
export const ProfessionalReferenceCheck = "ProfessionalReferenceCheck";
export const TrackApplication = "TrackApplication";
export const WalletListing = "WalletListing";
export const AddUPI = "AddUPI";
export const RedeemAmount = "RedeemAmount";

export const CONTAINER_MAX_WIDTH = "lg";

let BASE_URL = "https://test.api.lipijobs.com/api/v1";
let SITE_URL = "https://test.api.lipijobs.com";
let ASSET_URL = "https://lipijobs-stage.s3.ap-south-1.amazonaws.com";

// let BASE_URL = "http://localhost:8000/api/v1";

if (process.env.REACT_APP_ENV === "prod") {
  BASE_URL = "https://lipijobs.com/api/v1";
  SITE_URL = "https://lipijobs.com";
  ASSET_URL = "https://lipijobs-prod.s3.ap-south-1.amazonaws.com";
}

export const SMALL_PAGE_SIZE = 10;
export const getYears = () => {
  const currentYear = new Date().getFullYear();
  const previousYearCount = 50;
  const yearsArr = [];

  for (let i = 0; i < previousYearCount; i++) {
    yearsArr.push({
      value: currentYear - i,
      text: (currentYear - i).toString(),
    });
  }

  return yearsArr;
};

export enum CourseType {
  TENTH = 199,
  TWELTH = 200,
  BELOW10 = 546,
  GRADUATION = 201,
  MASTER = 202,
  DOCTORATE = 546,
}
export enum GradingType {
  Percentage = 29,
  Grade = 30,
}

export { BASE_URL, SITE_URL, ASSET_URL };
