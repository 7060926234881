import {
  VerificationKYC,
  EducationVerification,
  EmploymentVerification,
  CreditCheck,
  Criminal,
  AddressVerification,
  BankVerification,
  PCC,
  PVLF,
  PRC,
} from "../../../Icons";
import * as routePaths from "../../../RouteConstant";
export const verifications = [
  {
    title: "ID Verification / KYC",
    status: "NOT VERIFIED",
    description: "No Document required",
    price: "₹99/-",
    count: "0/1",
    icon: <VerificationKYC />,
    link: routePaths.IDVerification,
  },
  // {
  //   title: "Education Verification - EDUV",
  //   status: "PENDING",
  //   description: "Document required: Certificates, marksheets",
  //   price: "₹299/- per record",
  //   count: "0/3",
  //   icon: <EducationVerification />,
  //   link: "/education-list",
  //   code:"eduv"
  // },
  // {
  //   title: "Employment Verification - EMPV",
  //   status: "NOT VERIFIED",
  //   description: "Document required: Certificates, marksheets",
  //   price: "₹299/- per record",
  //   count: "0/6",
  //   icon: <EmploymentVerification />,
  //   link: "/employment",
  //   code:"empv"
  // },
  // {
  //   title: "Credit history check",
  //   status: "NOT VERIFIED",
  //   description: "Document required: Certificates, marksheets",
  //   price: "₹299/- per record",
  //   count: "0/1",
  //   icon: <CreditCheck />,
  //   link: "/credit-check",
  //   code:""
  // },
  // {
  //   title: "Criminal Court Record Verification - CCRV",
  //   status: "NOT VERIFIED",
  //   description: "Document required: Certificates, marksheets",
  //   price: "₹299/- per record",
  //   count: "0/1",
  //   icon: <Criminal />,
  //   link: "/id-verification",
  //   code:""
  // },
  // {
  //   title: "Address Verification",
  //   status: "NOT VERIFIED",
  //   description:
  //     "You have submitted the document, it is pending for review & hold back.",
  //   price: "₹299/- per record",
  //   count: "0/6",
  //   icon: <AddressVerification />,
  //   link: "/address-verification",
  // },
  // {
  //   title: "Bank account Verification",
  //   status: "NOT VERIFIED",
  //   description:
  //     "You have submitted the document, it is pending for review & hold back.",
  //   price: "₹299/- per record",
  //   count: "0/1",
  //   icon: <BankVerification />,
  //   link: "/bank-account-verification",
  // },
  // {
  //   title: "Police Clearance Certificate - PCC",
  //   status: "NOT VERIFIED",
  //   description:
  //     "You have submitted the document, it is pending for review & hold back.",
  //   price: "₹299/- per record",
  //   count: "0/1",
  //   icon: <PCC />,
  //   link: "/id-verification",
  // },

  // {
  //   title: "Professional Reference Check - PRC",
  //   status: "NOT VERIFIED",
  //   description:
  //     "You have submitted the document, it is pending for review & hold back.",
  //   price: "₹299/- per record",
  //   count: "0/1",
  //   icon: <PVLF />,
  //   link: "/id-verification",
  // },
  // {
  //   title: "Police Verification Via Law Firm - PVLF",
  //   status: "NOT VERIFIED",
  //   description:
  //     "You have submitted the document, it is pending for review & hold back.",
  //   price: "₹299/- per record",
  //   count: "0/1",
  //   icon: <PRC />,
  //   link: "/id-verification",
  // },
];
