import { Box, Dialog } from "@mui/material";
import { Text, Button, Form, InputBox } from "@lipihipi/ui-components";
import { useCallback, useContext, useState } from "react";
import handleToast, { success } from "../../util/Toast";
import { getPaymentOptions } from "../../Services/Service";
import { AppContext } from "../../App";

type Props = {
  open: boolean;
  onClose: any;
};
const TopupModal = ({ open, onClose }: Props) => {
  const [updatingWallet, setUpdatingWallet] = useState(false);
  const { state } = useContext<any>(AppContext);
  const onSuccessPayment = useCallback((res: any) => {
    onClose(false);
    handleToast("Wallet updated successfully", success);
  }, []);
  const handleSubmit = (values: Record<string, any>) => {
    return new Promise((rs, rj) => {
      getPaymentOptions(
        values,
        (data: any) => {
          const options = {
            ...data.data,
            handler: onSuccessPayment,
            modal: {
              ondismiss: function () {
                rs("");
              },
            },
          };

          const rzp1 = new (window as any).Razorpay(options);
          rzp1.open();
        },
        () => {
          rs("");
        }
      );
    });
  };
  return (
    <>
      <Box>
        <Dialog
          open={open}
          onClose={updatingWallet ? () => {} : onClose}
          keepMounted
        >
          <Box p={2}>
            <Text variant="h3">Top-up Wallet</Text>
            <Box py={1} px={1}>
              <Text variant="caption" sx={{ color: "#161458" }}>
                Available Balance
              </Text>
              <Text variant="h1" pt={1} sx={{ color: "#161458" }}>
                ₹{state.user.wallet.balance}
              </Text>
            </Box>

            <Box py={3}>
              <Text
                variant="caption"
                marginBottom={1}
                display="block"
                sx={{ color: "#161458" }}
              >
                Enter Amount
              </Text>
              <Form
              initialValues={{amount:''}}
                onSubmit={handleSubmit}
                render={({
                  handleChange,
                  submitForm,
                  isValid,
                  isSubmitting,
                }: any) => (
                  <>
                    <Box rowGap={2}>
                      <InputBox
                        required
                        name={"amount"}
                        fullWidth
                        placeholder="Amount"
                        label="Amount"
                        onChange={handleChange}
                      />
                    </Box>
                    <Box display="flex" columnGap={2}>
                      <Button
                        variant="outlined"
                        size="large"
                        sx={{ padding: "15px 40px", borderRadius: "8px" }}
                        onClick={() => onClose(false)}
                      >
                        Close
                      </Button>
                      <Button
                        variant="contained"
                        size="large"
                        sx={{ padding: "15px 20px", borderRadius: "8px" }}
                        {...(isValid ? { onClick: submitForm } : {})}
                        disabled={!isValid || isSubmitting}
                        loading={updatingWallet ? false : isSubmitting}
                      >
                        Proceed to Top-Up
                      </Button>
                    </Box>
                  </>
                )}
              />
            </Box>
          </Box>
        </Dialog>
      </Box>
    </>
  );
};

export default TopupModal;
