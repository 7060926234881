import { PrimaryLayout } from "../../Components";
import { Box, Grid } from "@mui/material";
import { Button, Card, Form, InputBox, Text } from "@lipihipi/ui-components";
import CheckIcon from "@mui/icons-material/Check";
import { Bank } from "../../Icons";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { addUpi, getUpiList } from "../../Services/Service";
import handleToast from "../../util/Toast";
import { useNavigate } from "react-router-dom";
import { REDEEM_AMOUNT, WALLET } from "../../RouteConstant";
import { useEffect, useState } from "react";

const AddUPI = () => {
  const navigate = useNavigate();
  const [vpaList, setVpaList] = useState([]);
  useEffect(() => {
    getUpiList((res: any) => {
      setVpaList(res.data);
    });
  }, []);
  const handleSubmit = (values: any) => {
    console.log(values);

    return new Promise((res: any) => {
      addUpi(
        values,
        (response: any) => {
          console.log(response);
          navigate(REDEEM_AMOUNT.replace(":id", response.data.id));
        },
        (error: any) => {
          handleToast(error.data.message);
          res("");
        }
      );
    });
  };
  return (
    <>
      <PrimaryLayout>
        <Grid justifyContent="center" container spacing={2}>
          <Grid item xs={12} sm={6} md={7}>
            <Card
              px={2}
              sx={{
                boxShadow: "none",
                border: "1px solid rgba(57, 57, 63, 0.10)",
              }}
              disableHeader
            >
              <Box>
                <Text variant="h1">Add UPI ID </Text>
                <Text variant="body1" mt={0.4}>
                  To redeem funds from your wallet, please add your UPI ID, and
                  then you can proceed.
                </Text>
              </Box>
              <Form
                initialValues={{ vpa: "" }}
                validateOnMount={true}
                onSubmit={handleSubmit}
                render={({
                  handleChange,
                  isValid,
                  isSubmitting,
                  submitForm,
                }: any) => {
                  return (
                    <>
                      <Box mt={4}>
                        <InputBox
                          name="vpa"
                          fullWidth
                          label="Enter UPI ID"
                          placeholder="Entere here..."
                          variant="outlined"
                          autoFocus
                          required
                          onChange={handleChange}
                        />
                      </Box>
                      {/* <Box mt={1} display="flex" justifyContent="space-between">
                        <Text variant="caption">Mukesh Saini</Text>
                        <Text
                          display="flex"
                          alignItems="center"
                          color="#00A470"
                          columnGap={0.6}
                          variant="caption"
                        >
                          <CheckIcon
                            sx={{
                              background: "#049B6B",
                              color: "#fff",
                              padding: "2px",
                              borderRadius: "50%",
                              fontSize: "16px",
                            }}
                          />
                          Verified
                        </Text>
                      </Box> */}

                      <Box display="flex" mt={8} columnGap={2}>
                        <Button
                          variant="outlined"
                          size="large"
                          fullWidth
                          sx={{ padding: "15px 40px", borderRadius: "8px" }}
                          onClick={()=>navigate(WALLET)}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          size="large"
                          fullWidth
                          sx={{ padding: "15px 20px", borderRadius: "8px" }}
                          disabled={!isValid || isSubmitting}
                          onClick={isValid ? submitForm : () => {}}
                        >
                          Verify
                        </Button>
                      </Box>
                    </>
                  );
                }}
              />

              <Box mt={5}>
                <Text variant="h3">Select from the linked UPI ID</Text>
                {vpaList.map((vpa: any) => (
                  <Box
                    px={2}
                    py={2}
                    mt={2}
                    display="flex"
                    justifyContent="space-between"
                    sx={{
                      boxShadow: "none",
                      borderRadius: "8px",

                      border: "1px solid rgba(22, 20, 88, 0.10)",
                    }}
                    onClick={()=>navigate(REDEEM_AMOUNT.replace(":id", vpa.id))}

                  >
                    <Box display="flex" columnGap={2}>
                      <Bank />

                      <Box display="flex" columnGap={0.6} alignItems="center">
                        <Text
                          variant="body1"
                          sx={{ fontWeight: "500", lineHeight: "120%" }}
                        >
                          {vpa.vpa}
                        </Text>
                      </Box>
                    </Box>

                    <ChevronRightIcon />
                  </Box>
                ))}
              </Box>
            </Card>
          </Grid>
        </Grid>
      </PrimaryLayout>
    </>
  );
};

export default AddUPI;
